<template>
  <div :class="!topBarShow ? 'winningList-container active' : 'winningList-container'">
    <van-nav-bar v-if="topBarShow" @click-left="goBack" left-arrow title="中奖记录" :border="false" />
    <van-nav-bar v-if="!topBarShow" @click-left="goBack" left-arrow :border="false" />
    <div class="no-data" v-if="!winningList.length">暂无中奖记录</div>
    <div class="list-wrap" v-if="winningList.length">
      <div
        :class="item.status ? 'list-item received' : 'list-item notClaimed'"
        v-for="item in winningList"
        :key="item.id"
        @click="receivePrize(item)"
      >
        <div class="level">{{ item.title }}</div>
        <div class="describe">{{ item.goods_title }}</div>
        <div class="time">{{ item.created_at }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { NavBar, Toast } from "vant";
import { useStore } from "vuex";
import { getPhoneSystem, isWeiChat } from "@/utils/environment";
import Api from "@/API";
export default {
  name: "winningList",
  components: {
    [NavBar.name]: NavBar,
  },
  setup(props) {

   
    //中奖记录列表
    let winningList = ref([]);
    //使用vuex
    const store = useStore();
    //使用路由
    const router = useRouter();
    //创建API对象
    const API = new Api();
    // 顶部栏显示隐藏
    const topBarShow = ref(true);
    topBarShow.value = !isWeiChat();
    //返回
    function goBack() {
      router.back();
    }
    //初始化函数
    function init() {
      getWinningRecord();
    }
    //获取中奖记录
    const getWinningRecord = async () => {
      let res = await API.getWinningRecord({
        token: store.state.Token,
      });
      winningList.value = res.data;
    };
    //领取奖品
    const receivePrize = async ({ type, id, status } = item) => {
      if (status) return false;
      switch (type) {
        case 3:
          try {
            if (isWeiChat()) {
              wx.miniProgram.navigateTo({
                // url: `/pages/confirmOrder2/confirmOrder2?record_id=${id}`,
                url: `/pages/gwc/gwc?record_id=${id}&type=0`,
              });
              return false;
            }
            if (getPhoneSystem() === "Android") {
              window.Android.turnPage(Number(id));
            }
            if (getPhoneSystem() === "IOS") {
              window.webkit.messageHandlers.turnPage.postMessage({
                id: Number(id),
              });
            }
          } catch (error) {
            Toast("出现了一个交互错误");
          }
          break;

        default:
          await API.receiveRewards({
            token: store.state.Token,
            record_id: id,
          });
          Toast.success("领取成功");
          getWinningRecord();
          break;
      }
    };
    onMounted(() => {
      init();
    });
    return {
      winningList,
      goBack,
      receivePrize,
      topBarShow,
    };
  },
};
</script>

<style lang="scss">
:root {
  --van-nav-bar-icon-color: #000;
}

.winningList-container.active {
  .van-nav-bar {
    padding-top: 0;
  }
}

.winningList-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .van-nav-bar {
    padding-top: 50px;
  }
  .no-data {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 38px;
    font-weight: 600;
    color: #000;
    letter-spacing: 2px;
  }
  .list-wrap {
    overflow-y: auto;

    .list-item {
      width: 100%;
      height: 261px;
      margin-bottom: 18px;
      position: relative;

      .level {
        position: absolute;
        top: 61px;
        left: 53px;
        font-size: 63px;
        font-weight: 800;
        color: #fffefe;
        text-shadow: 0px 6px 0px rgba(164, 25, 23, 0.56);
      }

      .describe {
        position: absolute;
        bottom: 67px;
        left: 54px;
        font-size: 33px;
        font-weight: 400;
        color: #ffffff;
      }

      .time {
        width: 223px;
        height: 43px;
        background: #fffdfd;
        position: absolute;
        top: 88px;
        left: 310px;
        font-size: 22px;
        color: #fc403f;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
      }
    }

    .list-item.notClaimed {
      background: url("../../assets/notClaimed.png") no-repeat;
      background-size: 100% 100%;
    }

    .list-item.received {
      background: url("../../assets/received.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>