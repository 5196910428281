//获取地址栏url后的参数值
export function getUrlParam(key) {
    var href = window.location.href;
    var url = href.split("?");
    if (url.length <= 1) {
        return "";
    }
    var params = url[1].split("&");
    for (var i = 0; i < params.length; i++) {
        var param = params[i].split("=");
        if (key == param[0]) {
            return param[1];
        }
    }
}
//获取手机系统
export function getPhoneSystem() {
    const u = navigator.userAgent;
    //Android终端
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    //iOS终端
    const isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    //手机系统
    if (isAndroid) {
        return "Android";
    }
    if (isIos) {
        return "IOS";
    }
}
//判断是不是微信浏览器
export function isWeiChat() {
    const u = window.navigator.userAgent.toLowerCase();
    if (
        u.match(/MicroMessenger/i) == "micromessenger" ||
        u.match(/_SQ_/i) == "_sq_"
    ) {
        return true;
    } else {
        return false;
    }
}

// 获得状态栏高度
export function getStatusBarHeight() {
    var immersed = 17.5;
    var ms = (/Html5Plus\/.+\s\(.*(Immersed\/(\d+\.?\d*).*)\)/gi).exec(navigator.userAgent);
    if (ms && ms.length >= 3) { // 当前环境为沉浸式状态栏模式
        immersed = parseFloat(ms[2]);// 获取状态栏的高度
    }
    return immersed;
}
