import request from '@/utils/request'

class Api {
    //领取奖励
    receiveRewards(params) {
        return request({
            url: '/v1.0/turntable/receive',
            method: 'post',
            params
        })
    }
    //获取中奖记录
    getWinningRecord(params) {
        return request({
            url: '/v1.0/turntable/prizeLog',
            method: 'get',
            params
        })
    }
    //获取活动信息
    getTruntableData(data) {
        return request({
            url: '/v1.0/turntable/index',
            method: 'get',
            params: data
        })
    }
    //获取抽奖结果
    getLuckDraw(data) {
        return request({
            url: '/v1.0/turntable/luckdraw',
            method: 'get',
            params: data
        })
    }
    //获取分享信息
    getShareDate(data) {
        return request({
            url: '/getSignPackage',
            method: 'get',
            params: data
        })
    }
    //添加分享记录
    addShareRecord(data) {
        return request({
            url: '/v1.0/turntable/luckdrawshare',
            method: 'get',
            params: data
        })
    }
}
export default Api