import axios from 'axios'
import { Toast } from 'vant';

// create an axios instance
const service = axios.create({
  baseURL: "https://api.mxl.mclandian.com", // url = base url + request url
  // baseURL: "https://api.jjf.mclandian.com", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      // 修改 Content-Type
      config.headers['Content-Type'] =
        'application/x-www-form-urlencoded';
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 1) {
      if (res.msg) {
        Toast.fail(res.msg);
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    Toast.fail("服务器错误");
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
